<!-- pagination -->
<template>
  <div class="pagination-box">
    <button @click="pre" :disabled='currentPage==1'><i class="el-icon-top"></i></button>
    <ul class="pagination">
      <li v-show="preMore" @click="current(1)">1</li>
      <li v-show="preMore"><i class="el-icon-more"></i></li>
      <li 
        :class="{active:item==currentPage}" 
        v-for="item,index in pageArr" 
        :key="item.toString()+index.toString()"
        @click="current(item,index)">{{item}}</li>
      <li v-show="afterMore"><i class="el-icon-more"></i></li>
      <li v-show="afterMore" @click="current(cpnTotal)">{{cpnTotal}}</li>
    </ul>
    <button @click="after" :disabled='currentPage==this.cpnTotal'><i class="el-icon-top"></i></button>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  name: "icPagination",
  //import引入的组件需要注入到对象中才能使用
  props:{
    total:{ // 传入总页数
      type:[Number,String],
      default(){
        return 5
      }
    },
    showPage:{
      type:Number,
      default(){
        // 这里可以定义需要显示的页数，需要中间值向上取整、中间值向下取整、需要显示的页数
        return 5
      }
    }
  },
  components: {
  },
  data() {
    //这里存放数据
    return {
      cpnTotal:Number(this.total),
      currentPage:1,
      pageArr:[1,2,3,4,5]
    };
  },
  //监听属性 类似于data概念
  computed: {
    dValue(){
      return Math.floor(this.showPage/2)
    },
    preMore(){
      return this.currentPage>this.dValue+1 && this.total>this.showPage
    },
    afterMore(){
      return this.currentPage<this.cpnTotal-this.dValue && this.total>this.showPage
    }
  },
  //监控data中的数据变化
  watch: {
    currentPage(newValue){
      // console.log(newValue,oldValve)
      // console.log(3,'3')
      // 变化一次重置一次pagearr
      // console.log(this.pageArr)
      let midArr=[]
      if(newValue<=this.dValue+1){
        if(this.cpnTotal<=this.showPage){
          this.pageArr=this.cpnTotal
        }else{
          this.pageArr=this.showPage
        }
      }else if(this.dValue+1<newValue && newValue<this.cpnTotal-this.dValue){
        // console.log('情况2')
        for(let i=0 ;i<this.showPage;i++){ // 0 1 2 3 4 5 6 7 8 9
          // console.log('修改数组')
          midArr.push(newValue-this.dValue+i)
          // this.pageArr=[newValue-2,newValue-1,newValue,newValue+1,newValue+2]
        }
        this.pageArr=midArr       
      }else if(newValue>=(this.cpnTotal-this.dValue+1)){
        // console.log('情况3')
        for(let i=0 ;i<this.showPage;i++){
          // console.log(i)
          if(this.cpnTotal+i-(this.showPage-1)>0){
            // break
            midArr.push(this.cpnTotal+i-(this.showPage-1))
          }         
          // this.pageArr=[this.cpnTotal-4,this.cpnTotal-3,this.cpnTotal-2,this.cpnTotal-1,this.cpnTotal]
        }
        this.pageArr=midArr 
      }      
      // 传出当前页
      this.$emit('currentPage',newValue)
      // console.log('展示页:',this.showPage,'浮点数:',this.dValue,'当前页:',newValue,'总页数:',this.dValue,'数组:',this.pageArr)
    }
  },
  //方法集合
  methods: {
    current(item){
      this.currentPage=item
    },
    pre(){
      this.currentPage>1?this.currentPage--:0
    },
    after(){
      this.currentPage<this.total?this.currentPage++:0
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 创建的时候判断一下页数
    this.cpnTotal<this.showPage?this.pageArr=this.cpnTotal:this.pageArr=this.showPage
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
/* @import url(); 引入公共css类 */

.pagination-box {
  padding: 2px 5px;
}
.pagination-box button{
  width: 28px;
  min-height: 30px;
  overflow: hidden;
  outline: none;
  border-radius: 2px;
  border: none;
  color: #606266;
  margin: -1px 5px 0px 5px;
  cursor: pointer;
}
.pagination-box button:disabled{
  background-color: #f4f4f5;
  cursor: not-allowed;
}
.pagination-box button:nth-of-type(1){
  transform: rotate(-90deg);
}
.pagination-box button:nth-of-type(2){
  transform: rotate(90deg);
}
.pagination {
  list-style: none;
  user-select: none;
  display: inline-block;
  vertical-align: top;
}
.pagination li {
  padding: 0 4px;
  vertical-align: top;
  display: inline-block;
  font-size: 13px;
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 30px;
  border-radius: 2px;
}
.pagination .active{
  background-color: #00BEA4;
  color: white;
  cursor: not-allowed;
}
</style>